$teal: #18c3bb;
$teal-border: #089d96;
$teal-link-border: #2ec8c0;
$teal-background: #7accc8;
$teal-background-light: #c4f0ee;
$teal-dark: #01645f;
$orange: #ff7801;
$pink: #d2096a;
$gray-blue: #6d97b9;
$light-green: #98cf61;
$black: #1e2022; // rgb(30, 32, 34)
$yellow-text: #ffc107;
$white: #ffffff;

$gray: #98a0b0;
$gray-text: #5c667a;
$gray-light: #777;
$gray-lighter: #b8b8b8;
$gray-lightest: #c3ccd2;
$gray-extra-light: #f3f7f9;
$gray-extra-light-home: #eaf0f5;
$gray-border: #d7dee2;
$gray-border-light: #dce0e0;
$gray-backgrounds: #d6dde1;
$gray-dark: #4f596c;
$gray-darker: #292f3b;
$gray-status: #8aaecb;
$gray-shadow: #cdcdcd;

$purple: #933cdd;
$purple-light: #d6baee;
$purple-lighter: #f6eefc;
$purple-dark: #5c667a;
$purple-status: #b791d8;

$green: #2fa205;
$green-light: #b3e8a0;
$green-extra-light: #ddf2d5;
$green-dark: #1d6b01;

$blue-light: #edfbfa;
$blue-text-secondary: #b9cbeb;
$blue-dark: #252e54;
$blue-darker: #050f39;
$blue-darkest: #001234;

$yellow-light: #fcf6ed;

$fuxia-background: #f9ebf2;
$fuxia-border: #f9cae0;
$fuxia: #e5167a;

$semi-transparent-dark-bg: rgba(#333, 0.7);
$orange-light: #ff9c44;
$orange-lighter: #ffd7b4;
$orange-dark: #9d4e09;
$silver-light: #c5c3c3;
$yellow: #ffd925;
$green-lighter: #8dc63f;
$silver-lighter: #bbbbc1;

$sorbus: #e564a2;
$red: #ff2e37;
$red-darker: #de2038;

@import "../../../../styles/mixins";

$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 600px;
$grid-breakpoint-md: 1024px;
$grid-breakpoint-lg: 1624px;
$gutter-width-mobile: 8px;
$gutter-width: 24px;

$grid-margin: 20px;
$neg-grid-margin: -20px;

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: $neg-grid-margin;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

// Manually create specific gap classes
.gap1 {
  @include gap(1);
}

.gap2 {
  @include gap(2);
}

.gap3 {
  @include gap(3);
}

.gap4 {
  @include gap(4);
}

.gap5 {
  @include gap(5);
}

.column {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: $grid-margin;
}

@media screen and (min-width: $grid-breakpoint-xs) {
  .column {
    flex-basis: 100%;
  }
}

@media screen and (min-width: $grid-breakpoint-sm) {
  .column {
    flex-basis: 50%;
  }
}

@media screen and (min-width: $grid-breakpoint-md) {
  .column {
    flex-basis: 33.3%;
  }
}

@media screen and (min-width: $grid-breakpoint-lg) {
  .column {
    flex-basis: 25%;
  }
}

$font-size-base: 15px;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 600;
$font-family-sans-serif: "Poppins";
$font-family-base: $font-family-sans-serif, sans-serif;
$text-color: #222;
$text-additional: #5c667a;
$text-disabled: #777;

// Transition defaults
$transition-duration: 0.05s;
$transition-timing-function: ease-in-out;

@mixin outline($color: rgba(#29d, 0.3)) {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 2px $color;
}

@mixin triangle($direction, $width, $height, $color) {
  @if ($direction == "up") {
    transform: rotate(135deg);
  } @else if ($direction == "down") {
    transform: rotate(-45deg);
  } @else if ($direction == "left") {
    transform: rotate(45deg);
  } @else if ($direction == "right") {
    transform: rotate(-135deg);
  }

  transform-origin: 0 0;
  border-width: $height calc($width / 2) 0;
  border-style: solid;
  border-color: transparent transparent $color $color;
  box-shadow: 0 2px 15px 0 rgba(30, 32, 34, 0.15);
  background-color: $color;
  width: 0;
  height: 0;
  content: "";
}

@mixin arrow($size, $color) {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -($size);
  margin-left: -($size);
  border-width: $size;
  border-bottom-width: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
}

@mixin arrow-after($size, $color) {
  display: block;
  position: absolute;
  bottom: 2px;
  margin-left: -(calc($size - 1px));
  border-width: calc($size - 1px);
  border-bottom-width: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
  content: " ";
}

@mixin arrow-with-shadow($arrow-size, $border-color, $arrow-color) {
  @include arrow($arrow-size, $border-color);

  &::after {
    @include arrow-after($arrow-size, $arrow-color);
  }
}

@mixin multi-line-ellipsis($line-height, $line-count, $bg-color) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: calc($line-height * $line-count);
  margin-right: -1em;
  padding-right: 0.5em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bg-color;
  }
}

@mixin hideScrollBars {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox

  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@function gap-size($number) {
  @return calc($number / 4) * 1rem;
}

@mixin gap($number) {
  gap: gap-size($number);
}

$screen-xs-slider: 320px;
$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xl: 1440px;

@mixin large-and-extra-large {
  @media (min-width: calc($screen-md + 1px)) {
    @content;
  }
}

@mixin extra-large {
  @media (min-width: calc($screen-lg + 1px)) {
    @content;
  }
}

@mixin large {
  @media (min-width: calc($screen-md + 1px)) and (max-width: $screen-lg) {
    @content;
  }
}

@mixin medium {
  @media (min-width: calc($screen-sm + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin small {
  @media (min-width: calc($screen-xs + 1px)) and (max-width: $screen-sm) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin xs-slider {
  @media (max-width: $screen-xs-slider) {
    @content;
  }
}

@mixin medium-and-large {
  @media (min-width: calc($screen-sm + 1px)) {
    @content;
  }
}

@mixin small-and-medium {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin xs-and-small {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin slv-large {
  @media (min-width: calc($screen-xl + 1px)) {
    @content;
  }
}

@mixin slv-medium {
  @media (min-width: calc($screen-lg + 1px)) and (max-width: $screen-xl) {
    @content;
  }
}

@mixin slv-small {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin slv-small-and-medium {
  @media (max-width: $screen-xl) {
    @content;
  }
}

@mixin smallDesktops {
  @media (max-width: calc($screen-xl - 1px)) {
    @content;
  }
}

@mixin ipads {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin phones {
  @media (max-width: calc($screen-sm - 1px)) {
    @content;
  }
}

// Global
$z-index-file-uploader-overlay: calc(2147483647 + 1); // chat icon + 1
$z-index-dropdown-body: 101;
$z-index-navbar: 1000;
$z-index-filters: 999;
$z-index-tooltip: 998;
$z-index-date-picker: 997;
$z-index-video-player-overlay: 1001;
$z-index-popover: 1;
$z-index-popver-triangle: 1;
$z-index-slick-arrow: 1;

// Dashboard
$z-index-dashboard-product-editor-action-bar: 100;
$z-index-dashboard-product-index-action-bar: 100;
$z-index-dashboard-product-index-notification-bar: 200;

// Marketplace
$z-index-mobile-filters: calc($z-index-navbar + 1);
$z-index-mobile-filters-action-bar: calc($z-index-mobile-filters + 1);

// Locations
$z-index-locations-map: calc($z-index-navbar + 1);
$z-index-locations-toggle-map: calc($z-index-locations-map + 1);
$z-index-locations-mobile-map: calc($z-index-navbar - 1);

// Modal navigation header
$z-index-modal-navigation-header: 1;
$z-index-invitation-modal: 1010;
$z-index-invitation-modal-overlay: 1000;

@import "app/styles/screens";
@import "app/styles/colors";
@import "app/styles/fonts";
@import "../admin_dashboard_local_mixins.scss";

@import "app/styles/layout";
@import "app/styles/z-indexes";
@import "app/styles/misc";

$sort-icon-cell: 10px;
$sort-icon-pad: 5px;
$header-controls-padding: 8px;

.container {
  padding-bottom: 80px;

  @include small-and-medium {
    padding-bottom: 40px;
  }

  @include phoneSmall {
    padding-bottom: 30px;
  }
}

.contentWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;

  @include small-and-medium {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
  }

  @include xs {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include large {
    max-width: 904px;
  }

  @include extra-large {
    max-width: 1110px;
  }

  .titleMain {
    color: $black;
    font-family: $font-family-sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 30px;

    @include small-and-medium {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 20px;
    }

    @include xs {
      font-size: 22px;
      line-height: 26px;
    }
  }
}

.paginationBar {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  color: $gray-text;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  text-align: right;
  align-items: center;

  @include small-and-medium {
    margin-top: 30px;
  }

  @include xs {
    flex-direction: column;
    text-align: center;

    .paginationInfo {
      margin-top: 20px;
    }
  }
}

.noRecordCard {
  border-radius: 3px;
  background-color: $yellow-light;
  color: $orange-dark;
  font-family: $font-family-sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 30px;

  @include phoneSmall {
    padding: 20px;
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
}

.headerControls {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  align-items: center;

  @include phoneSmall {
    grid-auto-flow: row;
    grid-row-gap: 12px;
  }
}

.searchField {
  padding-top: $header-controls-padding;
  padding-bottom: $header-controls-padding;
}

.dropdown {
  padding-top: $header-controls-padding;
  padding-bottom: $header-controls-padding;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  min-width: 150px;
}

.dropdownBody {
  display: grid;
  grid-template-columns: minmax(160px, 1fr);
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-row-gap: 0;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 250px;
  overflow-y: auto;
}

.pointer {
  cursor: pointer;
}

.dropdownBodyRow {
  display: grid !important;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 7px;
  align-items: center;
  padding: 7px 0 !important;
  border-bottom: 1px solid lighten($gray-lightest, 5%) !important;
  text-align: left;

  &:last-child {
    border-bottom: 0 !important;
  }

  .label {
    margin: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.id,
.product,
.provider,
.user,
.company,
.status {
  &.tableHeaderCell {
    .controlRightAlign {
      color: $gray-text;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      padding-right: 10px;
    }
  }
}

.filters {
  margin-bottom: 30px;

  .searchIcon {
    left: auto;
    right: 0;
    border-right: none;
    width: 38px;

    .icon {
      height: 24px;
      width: 24px;
    }
  }

  .searchField {
    padding-left: 15px;
    padding-right: 38px;
  }

  .dropdownBody {
    width: 100%;
  }

  .exportToCsvButton {
    justify-content: flex-end;
  }
}

.actionBar {
  display: grid;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: $teal;
  transition: transform $transition-duration $transition-timing-function;
  z-index: $z-index-dashboard-product-index-action-bar;

  &.shown {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(100%);
  }
}

.actionBarInner {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.actionBarInfo {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 15px;
  color: $white;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;

  .linkCancel {
    font-weight: 500;
  }

  @include xs {
    grid-template: none;

    .linkCancel {
      justify-self: center;
    }
  }
}

.actionBarDropdown {
  background-color: $white;
  border-color: $white !important;
  color: $black;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 35px 10px 20px;
  line-height: 20px;
}

.actionBarSpinner {
  display: flex;
  position: relative;
  min-width: 60px;
}

.actionBarError {
  display: grid;
  justify-content: end;
  color: $white;
}

.orderDataview {
  margin-bottom: 25px;

  @include small-and-medium {
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .checkbox {
    margin-bottom: -4px;
    padding-right: 10px;
    width: 32px;
  }

  .id {
    padding-right: 10px;
    width: 150px;
  }

  .product {
    padding-right: 10px;
    width: 300px;
  }

  .provider,
  .user,
  .company {
    padding-right: 10px;
    position: relative;
    width: 160px;
  }

  .status {
    width: 115px;
  }

  .orderDataviewHeader {
    background-color: $gray-extra-light;
    border-bottom: 1px solid $gray-lightest;
    display: flex;
    align-items: center;
    padding: 14px 16px;

    .iconSort {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .dataRow {
    color: $gray-text;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 15px 16px;

    &:nth-child(even) {
      background-color: $gray-extra-light;
    }

    // FOR PER PAGE ITEMS COUNT
    @include small-and-medium {
      &:nth-child(4),
      &:nth-child(5) {
        .cardInfo {
          top: auto;
          bottom: 100%;
        }

        .statusDropdown .dropdownBody {
          transform: none;
          bottom: 100%;
        }
      }
    }

    .title {
      color: $black;
      font-weight: 500;
      line-height: 20px;
    }

    .subTitle {
      font-size: 13px;
      line-height: 15px;
    }

    .dot {
      background-color: $gray-lightest;
      border-radius: 100%;
      display: inline-block;
      height: 3px;
      width: 3px;
      vertical-align: middle;
      margin: 0 5px;
    }

    .link {
      font-weight: 500;
    }

    .userDropdown {
      display: inline-block;
      position: relative;

      & > button.link {
        max-width: 130px;
        text-align: left;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        display: inline-block;
      }

      &:hover {
        .cardInfo {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .cardInfo {
      position: absolute;
      top: 100%;
      left: 0;
      border-radius: 10px;
      background-color: $white;
      box-shadow: 0 2px 30px 0 rgba(30, 32, 34, 0.15);
      padding: 30px;
      min-width: 400px;
      display: flex;
      opacity: 0;
      visibility: hidden;
      z-index: 21;

      @include small-and-medium {
        right: 0;
        padding: 18px;
        left: auto;
        min-width: 320px;
      }

      .iconClose {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px;
      }

      .avatar {
        flex: 0 0 60px;
        height: 60px;
        align-self: flex-start;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        margin-right: 15px;

        img {
          border-radius: 100%;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      .details {
        word-break: break-word;

        .name {
          color: $black;
          font-family: $font-family-sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
        }

        .designation {
          color: $gray-text;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          line-height: 20px;
        }

        .phone {
          color: $gray-text;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          line-height: 20px;
          margin-top: 10px;
          margin-bottom: 5px;

          @include small-and-medium {
            margin-top: 4px;
          }
        }

        .email {
          margin-bottom: 10px;

          @include small-and-medium {
            margin-bottom: 5px;
          }
        }

        .email,
        .nav {
          a {
            color: $teal;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            position: relative;
          }
        }

        .nav {
          a {
            & + a::before {
              content: ".";
              color: $silver-lighter;
              vertical-align: top;
              font-size: 24px;
              display: inline-block;
              margin-left: 5px;
              margin-right: 5px;
              line-height: 10px;
            }
          }
        }
      }
    }

    .statusDropdown {
      display: inline-block;

      .dropdown {
        color: $white;
        min-width: 115px;
        padding: 5px 12px;
        font-size: 13px;
        border-radius: 34px;

        &.inProgress {
          background-color: $orange-light;
          border-color: $orange-light;
        }

        &.finalized {
          background-color: $green;
          border-color: $green;
        }

        &.cancelled {
          background-color: $gray-lightest;
          border-color: $gray-lightest;
        }

        &.changing {
          background-color: $teal;
          border-color: $teal;
        }
      }

      .dropdownBody {
        display: block;
        width: 100%;
      }
    }
  }
}

.notificationBar {
  display: grid;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  right: 0;
  height: 60px;
  transition: transform $transition-duration $transition-timing-function;
  z-index: $z-index-dashboard-product-index-notification-bar;
  background-color: $orange-light;
  border-color: darken($orange-light, 5%);
  border-radius: 4px;
  width: 740px;
  @include xs {
    width: 100%;
    font-size: 15px;
    padding-top: 5px;
  }
  word-wrap: break-word;
}

.notificationBarContainer {
  text-align: center;
}

.notificationBarInner {
  display: grid;
  @include xs {
    display: inline-block;
  }
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.notificationBarError {
  justify-content: end;
  color: $white;
  word-wrap: break-word;
  margin: auto;
  width: 100%;
  display: inline-block;
}
